/******************************************
 *  Author : Suraj Sharma
 *  Created On : Fri Feb 19 2021
 *  File : AuthStateHandler.js
 *******************************************/
/**
 * For nextjs project we have no central mechanism for rehydrating
 * redux state when the user opens any webpage, conditional to his authenticated
 * state. This util helps us achieve that. 
 */

import {preAuthPaths, postAuthPaths} from '../config/constants/NavigationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllowedTreeCount, fetchPlantedTreeCount, resetTreeState } from '../store/trees/ThunkActions';

const AuthStateHandler = (isAuthenticated, pathName) => {
    /**
     * First check if the current path is available to
     * this user
     */
    const dispatch = useDispatch();
    const { credentials: { email } } = useSelector((reduxState) => reduxState.authReducer);
    
    const { 
        plantedTree, 
        allowedTree, 
    } = useSelector((reduxState) => reduxState.treesReducer);

    let isExist = false;

    const checkPathObject = isAuthenticated ? postAuthPaths : preAuthPaths;

    Object.values(checkPathObject).forEach((path) => {
        if (path === pathName || (pathName.includes(path) && path !== '/')){
            isExist = true;
        }
    });

    if(isAuthenticated && typeof window !== 'undefined') {
        // console.log('inside AuthStateHandler, plantedTree => ', plantedTree, 'allowed tree => ', allowedTree)
        /**
         * Make api call to replenish all plantedTreeCount and allowedTreeCount
         */
        if (plantedTree < 0) {
            dispatch(fetchPlantedTreeCount(email));
        }
        
        if(allowedTree < 0){
            dispatch(fetchAllowedTreeCount(email));
        }
    }

    return isExist;
}

export default AuthStateHandler;