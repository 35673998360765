/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Dec 22 2020
 *  File : Actions.js
 *******************************************/
import {
	FETCH_TREES,
	UPDATE_TREES_STATUS,
	UPDATE_PLANTED_TREES_COUNT_STATUS,
	UPDATE_ALLOWED_TREES_COUNT_STATUS,
	CHOOSE_TREES,
	UN_CHOOSE_TREES,
	STORE_MEETING_DATA,
	CLAIM_TREE_REQUEST,
	RESET_TREE_STATE,
	CLEAR_TREE_TRANSACTION,
	CLEAR_CHOOSEN_TREES,
	FETCH_COMPANY_TREES,
	UPDATE_COMPANY_TREES_STATUS,
} from './Types';

export function fetchTrees(apiResponseStatus, treeData, totalTrees=0) {
	return {
		type: FETCH_TREES,
		responseStatus: apiResponseStatus,
		treeData,
		totalTrees,
	};
}

export function updateTreesStatus(isLoading, apiResponseStatus, apiResponseMessage = null) {
	return {
		type: UPDATE_TREES_STATUS,
		isLoading,
		responseStatus: apiResponseStatus,
		responseMessage: apiResponseMessage
	};
}

export function storeMeetingData(
	urlApiSource, 
	urlSelectedTreeCount, 
	urlTransactionId, 
	urlEmailId,
	urlRestaurantName,
	urlRestaurantId
	) {
	return {
		type: STORE_MEETING_DATA,
		urlApiSource,
		urlSelectedTreeCount,
		urlTransactionId,
		urlEmailId,
		urlRestaurantName,
		urlRestaurantId
	};
}

export function chooseTree(choosenTree) {
	return {
		type: CHOOSE_TREES,
		choosenTree,
	};
}

export function unChooseTree(unChoosenTree) {
	return {
		type : UN_CHOOSE_TREES,
		unChoosenTree
	}
}

export function claimTreeRequest(apiResponseMessage,apiResponseStatus) {
	return {
		type: CLAIM_TREE_REQUEST,
		responseMessage: apiResponseMessage,
		claimResponseStatus: apiResponseStatus
	};
}

export function updatePlantedTreesCountStatus(isLoading, apiResponseStatus, responseData) {
	return {
		type: UPDATE_PLANTED_TREES_COUNT_STATUS,
		isLoading,
		responseStatus: apiResponseStatus,
		responseData,
	};
}

export function updateAllowedTreesCountStatus(isLoading, apiResponseStatus, responseData) {
	return {
		type: UPDATE_ALLOWED_TREES_COUNT_STATUS,
		isLoading,
		responseStatus: apiResponseStatus,
		responseData,
	};
}

export function resetTreeState() {
	return {
		type: RESET_TREE_STATE
	}
}

export function clearTreeTransaction() {
	// this action is called inside verify panacea bill detail
	// where we need to reset everything except bill details
	return {
		type: CLEAR_TREE_TRANSACTION
	}	
}

export function clearChoosenTrees() {
	// this action is called inside verify panacea bill detail
	// where we need to reset everything except bill details
	return {
		type: CLEAR_CHOOSEN_TREES
	}	
}

export function updateCompanyTreesStatus(isLoading, apiResponseStatus, apiResponseMessage = null) {

	return {

		type: UPDATE_COMPANY_TREES_STATUS,

		isLoading,

		responseStatus: apiResponseStatus,

		responseMessage: apiResponseMessage

	};

}


export function fetchCompanyTrees(apiResponseStatus, treeData) {

	return {

		type: FETCH_COMPANY_TREES,

		responseStatus: apiResponseStatus,

		treeData,

	};

}
