/******************************************

 *  Author : Nikhil Unni

 *  Created On : Wed Oct 27 2021

 *  File : Reducer.js

 *******************************************/

import {

    GET_COMPANY_FOREST_INFO
  
  } from './Types';
  
  
  const initialState = {
  
    isValid: false,
  
    isMpUser: false,
  
    isCfdUser: false,
  
    mpUserData: {},
  
    loadingStatus: 'loading'
  
  };
  
  
  export default function companyForestReducer(state = initialState, action) {
  
    switch (action.type) {
  
      case GET_COMPANY_FOREST_INFO:
  
  
        return {
  
          ...state,
  
          isValid: action.isValid,
  
          isMpUser: action.isMpUser,
  
          isCfdUser: action.isCfdUser,
  
          mpUserData: action.mpUserData,
  
          loadingStatus: action.loadingStatus
  
        }
  
  
      default:
  
        return state;
  
    }
  
  }