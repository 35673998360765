/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Dec 22 2020
 *  File : CustomerInfoService.js
 *******************************************/
import { apiEndPoints, RESTAURANT_SEARCH_BASE } from '../../config/constants/ApiServiceConstants';
import NetworkUtil from '../../utils/NetworkUtil';

class CustomerInfoService {
    constructor(appAPIServer) {
        this.appAPIServer = appAPIServer;
    }

	/**
	 * API Service method for verifying the bill details.
	 * @param data {
     *  amount,
     *  date,
     *  time,
     *  email
     * }
	 * @returns APIResponse
	 */

    async verifyPanaceaUser(data) {
        let result = null;
        await this.appAPIServer
            .post(apiEndPoints.customerInfo.verifyPanaceaUser, data)
            .then(
                // onFullFilled
                (value) => {
                    result = NetworkUtil.buildResult(
                        null,
                        value.status,
                        null,
                        value.data,
                    );
                },

                // onRejected
                (reason) => {
                    const { response } = reason;

                    result = NetworkUtil.buildResult(
                        response?.data?.message,
                        response?.status,
                        response?.data.message,
                        null,
                    );
                },
            )
            .catch((error) => {
                throw error;
            });

        return result;
    }

    async searchRestaurants(searchText) {
        let result = null;
        await this.appAPIServer
            .get(`${RESTAURANT_SEARCH_BASE}?company=${searchText}`)
            .then(
                // onFullFilled
                (value) => {
                    result = NetworkUtil.buildResult(
                        null,
                        value.status,
                        null,
                        value.data,
                    );
                },

                // onRejected
                (reason) => {
                    const { response } = reason;

                    result = NetworkUtil.buildResult(
                        response?.data?.message,
                        response?.status,
                        response?.data.message,
                        null,
                    );
                },
            )
            .catch((error) => {
                throw error;
            });

        return result;
    }

}

export default CustomerInfoService;
