/******************************************
 *  Author : Suraj Sharma
 *  Created On : Wed Mar 17 2021
 *  File : DeflateBase64Data.js
 *******************************************/
import pako from 'pako';

export default function deflateBase64Data(base64Text) {
    return new Promise((resolve, reject)=>{
        // Decode base64 (convert ascii to binary)
        const strData = atob(base64Text);
        // Convert binary string to character-number array
        const charData = strData.split('').map((x)=> x.charCodeAt(0));
        // Turn number array into byte-array
        const binData = new Uint8Array(charData);
        // Pako magic
        const data = pako.inflate(binData);
        try {
            // Convert gunzipped byteArray back to ascii string:
            const decodedString = new TextDecoder().decode(data);
            resolve(JSON.parse(decodedString));
        } catch (error) {
            reject((error));
        }
    })
}