/******************************************

 *  Author : Nikhil Unni

 *  Created On : Wed Oct 27 2021

 *  File : ImpossibleVideoService.js

 *******************************************/

import { apiEndPoints } from '../../config/constants/ApiServiceConstants';

import NetworkUtil from '../../utils/NetworkUtil';


class CompanyForestService {

  constructor(appAPIServer) {

    this.appAPIServer = appAPIServer;

  }


  async getCompanyForestInfo(args) {

    let result = null;


    await this.appAPIServer

      .post(apiEndPoints.trees.getCompanyForest, {forestUrl: args.pathName})

      .then(

        // onFullFilled

        (value) => {

          result = NetworkUtil.buildResult(

            null,

            value.status,

            null,

            value.data,

          );

        },


        // onRejected

        (reason) => {

          const { response } = reason;


          result = NetworkUtil.buildResult(

            response?.data?.message,

            response?.status,

            response?.data.message,

            null,

          );

        },

      )

      .catch((error) => {

        throw error;

      });


    return result;

  }

}


export default CompanyForestService;