/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Dec 22 2020
 *  File : Types.js
 *******************************************/

export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';
export const UPDATE_AUTH_PERSIST_CHECK_STATUS = 'UPDATE_AUTH_PERSIST_CHECK_STATUS';
export const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';
export const UPDATE_AUTH_MESSAGE = 'UPDATE_AUTH_MESSAGE';
export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';