/******************************************

 *  Author : Nikhil Unni

 *  Created On : Wed Oct 27 2021

 *  File : Types.js

 *******************************************/


export const GET_COMPANY_FOREST_INFO = 'GET_COMPANY_FOREST_INFO';