/* eslint-disable no-underscore-dangle */
/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Dec 22 2020
 *  File : ThunkActions.js
 *******************************************/

import {apiResponseStatuses, httpStatusCodes} from '../../config/constants/ApiServiceConstants';
import {treeInfoService} from '../../services/api';
import * as treesActions from './Actions';
import { TreeTransactionUtil, deflateBase64Data, getTypeOfDevice } from '../../utils';

export const fetchTrees = (postBodyData) => async (dispatch) => {
	// when api call is happening we change the mycourses redux state
	dispatch(treesActions.updateTreesStatus(true, apiResponseStatuses.IDLE));
	const treeData = [];
	try {
		const response = await treeInfoService.fetchTrees(postBodyData);
		switch (response.httpStatusCode) {
			case httpStatusCodes.SUCCESS_OK: {
				// lets change the api response for better component consumption
				// so that each component can be well reused
				const dbStatus = response.data.status; 
				// if the data attribute of response.data has length > 0
				// which means the api call was success with some trees
				if (
					response.data.data !== null &&
					Object.keys(response.data.data).length > 0 
					&& dbStatus === httpStatusCodes.SUCCESS_OK
				) {
					// since the response is dependent of precision or zoom level
					// precision >= 8.18 will have different response
					let responseTrees;
					if (response.data.event.precision >= 8.18) {
						// for response where markers are shown
						// since the data is base64 zipped we have to 
						// deflate it
						deflateBase64Data(response.data.data)
						.then((res)=>{
							dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, [...res]));
						})
						// responseTrees = response.data.data;
						// treeData.push(...responseTrees);
					} else {
						// for precision where clusters are shown instead of markers
						responseTrees = response.data.data.cells.buckets;						
						const size = responseTrees.length;
						for (let i = 0; i < size; i += 1) {
							const el = {
								count: responseTrees[i].doc_count,
								lat: parseFloat(responseTrees[i].center_lat.value),
								lng: parseFloat(responseTrees[i].center_lon.value),
							};
							treeData.push(el);
						}
						
						dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, treeData));
					}
				} else {
					// when there are no trees in api request or tree data is null
					dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, []));	
				}
				break;
			}
			default:
				// anything other than 200 means an error
				dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, []));
				break;
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log('error', error);
		dispatch(treesActions.updateTreesStatus(false, apiResponseStatuses.ERROR));
	}

	return({coordinates: treeData, apiZoom: 0});
};

export const fetchDynamicTrees = (postBodyData) => async (dispatch) => {
	// when api call is happening we change the mycourses redux state
	dispatch(treesActions.updateTreesStatus(true, apiResponseStatuses.IDLE));
	const treeData = [];
	let zoomLevel = 0;
	const system = getTypeOfDevice();
	try {
		const response = await treeInfoService.fetchDynamicTrees({...postBodyData, system});
		switch (response.httpStatusCode) {
			case httpStatusCodes.SUCCESS_OK: {
				// lets change the api response for better component consumption
				// so that each component can be well reused
				const dbStatus = response.data.status; 
				// if the data attribute of response.data has length > 0
				// which means the api call was success with some trees
				if (
					response.data.data !== null && 
					Object.keys(response.data.data).length > 0 
					&& dbStatus === httpStatusCodes.SUCCESS_OK
				) {
					zoomLevel = response?.data?.zoom;
					const responseTrees = response?.data?.data?.large_grid.buckets;
					const size = responseTrees.length;
					for (let i = 0; i < size; i += 1) {
						const el = {
							count: responseTrees[i].doc_count,
							lat: parseFloat(responseTrees[i].center_lat.value),
							lng: parseFloat(responseTrees[i].center_lon.value),
						};
						treeData.push(el);
					}

					dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, treeData));
					// if(size > 0){
					// 	const el = {
					// 		count: responseTrees[0].doc_count,
					// 		lat: parseFloat(responseTrees[0].center_lat.value),
					// 		lng: parseFloat(responseTrees[0].center_lon.value),
					// 	};
						
					// 	treeData.push(el);
					// }

				} else {
					dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, []));
				}
				break;
			}
			default:
				// anything other than 200 means an error
				dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, []));
				break;
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log('error', error);
		dispatch(treesActions.updateTreesStatus(false, apiResponseStatuses.ERROR));
	}
	return({coordinates: treeData, apiZoom: zoomLevel});
}

export const fetchTreeDetail = (tokenId) => new Promise(async(resolve, reject)=> {
	const postBodyData = {
		tree_token: tokenId
	};	

	try {
		const {
			httpStatusCode,
			data
		} = await treeInfoService.fetchTreeDetail(postBodyData);
	
		switch (httpStatusCode) {
			case httpStatusCodes.SUCCESS_OK: {
				resolve(data.tree);
				break;
			}
			default: {
				reject(data);
				break;
			}
		}		
	} catch (error) {
		reject(error);
	}
	
});

export const fetchPlantedTreeCount = (email) => async (dispatch) => {
	dispatch(treesActions.updatePlantedTreesCountStatus(true, apiResponseStatuses.IDLE));

	try {
		const {
			httpStatusCode,
			data: {
				data: {tree_count: totalTree = 0, id: walletId = 0},
			},
		} = await treeInfoService.fetchPlantedTreeCount(email);

		switch (httpStatusCode) {
			case httpStatusCodes.SUCCESS_OK: {
				dispatch(
					treesActions.updatePlantedTreesCountStatus(false, apiResponseStatuses.SUCCESS, {
						totalTree,
						walletId
					}),
				);
				break;
			}
			default:
				dispatch(treesActions.updatePlantedTreesCountStatus(false, apiResponseStatuses.ERROR, null));
				break;
		}
	} catch (error) {
		dispatch(treesActions.updatePlantedTreesCountStatus(false, apiResponseStatuses.ERROR, null));
	}
};

export const fetchAllowedTreeCount = (email) => async (dispatch) => {
	dispatch(treesActions.updateAllowedTreesCountStatus(true, apiResponseStatuses.IDLE));

	try {
		const {
			httpStatusCode,
			data: {
				allowed_tree_count: totalTree = 0,
				list: transactions = {
					panacea: [],
					sustainable_meeting: [],
				},
			},
		} = await treeInfoService.fetchAllowedTreeCount(email);

		switch (httpStatusCode) {
			case httpStatusCodes.SUCCESS_OK: {
				/**
				 * NOTE: We may require a bit of work in this part coz of
				 * we may need to keep track of transactions and respective tree count
				 * To do this we can keep a list of counters for transactions
				 */
				TreeTransactionUtil(transactions).then((res)=>{
					dispatch(
						treesActions.updateAllowedTreesCountStatus(false, apiResponseStatuses.SUCCESS, {
							totalTree,
							transactions: res,
						}),
					);
				})
				break;
			}
			default:
				dispatch(
					treesActions.updateAllowedTreesCountStatus(false, apiResponseStatuses.ERROR, null),
				);
				break;
		}
	} catch (error) {
		dispatch(treesActions.updateAllowedTreesCountStatus(false, apiResponseStatuses.ERROR, null));
	}
};

export const fetchAllClaimedTrees = () => {
	console.log('fetching claimed')
};

export const claimTreeRequest = (transactionId, transactionSystem, tokens, email) => async (dispatch) => {
	const postBodyData = {
		isClaim: true,
		tokens,
		email
	};
	
	if(transactionId){
		postBodyData.transaction_id = transactionId;
		postBodyData.transaction_system = transactionSystem;
	}

	dispatch(treesActions.updateTreesStatus(true, apiResponseStatuses.IDLE));

	try {
		dispatch(treesActions.claimTreeRequest('request started'));		
		const response = await treeInfoService.claimTreeRequest(postBodyData);
		switch (response.httpStatusCode) {
			case httpStatusCodes.SUCCESS_OK: {
				if(response.data.status === httpStatusCodes.SUCCESS_OK) {
					
					/**
					 * This status code is concerned with the server
					 * - this block of code will be executed when claiming is done
					 * This block will clear choosen trees in redux state
					 * 
					 * NOTE: The following step has already been done at the start of try block
					 * the reason being choosen trees are persisting in nature and if user manually refreshes
					 * the page this data need to be cleaned
					 */

					dispatch(treesActions.claimTreeRequest(response.data.message,apiResponseStatuses.SUCCESS));
				}else {
					dispatch(treesActions.claimTreeRequest(response.data.message, apiResponseStatuses.ERROR));
				}
				break;
			}
			default:
				dispatch(treesActions.updateTreesStatus(false, apiResponseStatuses.ERROR, response.data.message));
				break;
		}
	}catch(e) {
		dispatch(treesActions.updateTreesStatus(false, apiResponseStatuses.ERROR));
		console.log(e);
	}
};

export const storeMeetingData = ({
	urlApiSource, 
	urlSelectedTreeCount, 
	urlTransactionId, 
	urlEmailId=null,
	urlRestaurantName=null,
	urlRestaurantId=null
}) => async (dispatch) => {
	dispatch(treesActions.storeMeetingData(
		urlApiSource, 
		urlSelectedTreeCount, 
		urlTransactionId, 
		urlEmailId,
		urlRestaurantName,
		urlRestaurantId
	));
};

export const chooseTree = ({choosenTree}) => async (dispatch) => {
	dispatch(treesActions.chooseTree(choosenTree));
};

export const unChooseTree = ({unChoosenTree}) => async (dispatch) => {
	dispatch(treesActions.unChooseTree(unChoosenTree));
};

export const resetTreeState = () => async(dispatch) => {
	dispatch(treesActions.resetTreeState());
}

export const verifyTransaction = async({transactionId, treeCount, email, transaction_system}) => {
	const response = await treeInfoService.verifyTransaction({
		transaction_id: transactionId,
		count: treeCount,
		email : email,
		transaction_system: transaction_system
	});
	return response;
}

export const clearChoosenTrees = () => async(dispatch) => {
	dispatch(treesActions.clearChoosenTrees());
}

export const fetchCompanyTrees = (postBodyData) => async (dispatch) => {
	// when api call is happening we change the mycourses redux state
	dispatch(treesActions.updateTreesStatus(true, apiResponseStatuses.IDLE));
	const treeData = [];
	try {
		const response = await treeInfoService.fetchCompanyTrees(postBodyData);
		switch (response.httpStatusCode) {
			case httpStatusCodes.SUCCESS_OK: {
				// lets change the api response for better component consumption
				// so that each component can be well reused
				const dbStatus = response.data.status; 
				// if the data attribute of response.data has length > 0
				// which means the api call was success with some trees
				if (
					response.data.data !== null &&
					Object.keys(response.data.data).length > 0 
					&& dbStatus === httpStatusCodes.SUCCESS_OK
				) {
					// since the response is dependent of precision or zoom level
					// precision >= 8.18 will have different response
					let responseTrees;
					if (response.data.event.precision >= 8.18) {
						// for response where markers are shown
						// since the data is base64 zipped we have to 
						// deflate it
						deflateBase64Data(response.data.data)
						.then((res)=>{
							dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, [...res],response.data.totalCompanyTrees));
						})
						// responseTrees = response.data.data;
						// treeData.push(...responseTrees);
					} else {
						// for precision where clusters are shown instead of markers
						responseTrees = response.data.data;						
						const size = responseTrees.length;
						for (let i = 0; i < size; i += 1) {
							const el = {
								count: responseTrees[i].doc_count,
								lat: parseFloat(responseTrees[i].center_lat.value),
								lng: parseFloat(responseTrees[i].center_lon.value),
							};
							treeData.push(el);
						}
						
						dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, treeData,response.data.totalCompanyTrees));
					}
				} else {
					// when there are no trees in api request or tree data is null
					dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, [],response.data.totalCompanyTrees));	
				}
				break;
			}
			default:
				// anything other than 200 means an error
				dispatch(treesActions.fetchTrees(apiResponseStatuses.SUCCESS, [], 0));
				break;
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log('error', error);
		dispatch(treesActions.updateTreesStatus(false, apiResponseStatuses.ERROR));
	}

	return({coordinates: treeData, apiZoom: 0});
};
