/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Dec 22 2020
 *  File : Reducers.js
 *******************************************/
import {apiResponseStatuses} from '../../config/constants/ApiServiceConstants';
import {
	FETCH_TREES,
	UPDATE_TREES_STATUS,
	CHOOSE_TREES,
	UN_CHOOSE_TREES,
	STORE_MEETING_DATA,
	UPDATE_PLANTED_TREES_COUNT_STATUS,
	UPDATE_ALLOWED_TREES_COUNT_STATUS,
	CLAIM_TREE_REQUEST,
	RESET_TREE_STATE,
	CLEAR_TREE_TRANSACTION,
	CLEAR_CHOOSEN_TREES
} from './Types';

const initialState = {
	isLoading: false,
	isFetchingTrees: false,
	responseStatus: apiResponseStatuses.IDLE,
	responseMessage: null,
	treeData: [],
	urlApiSource: null,
	urlSelectedTreeCount: 1,
	urlTransactionId: null,
	urlEmailId: null,
	urlRestaurantName: null,
	urlRestaurantId: null,
	choosenTrees: {},
	plantedTree: -1,
	walletId: -1,
	allowedTree: -1,
	transactions: {
		list: [],
		allAvailableTreeCounts: []
	},
	claimResponseStatus:apiResponseStatuses.IDLE,
};

export default function treesReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_TREES:
			return {
				...state,
				isFetchingTrees: false,
				responseStatus: action.responseStatus,
				treeData: action.treeData,
				totalTrees: action.totalTrees,
			};
		case UPDATE_TREES_STATUS:
			return {
				...state,
				isFetchingTrees: action.isLoading,
				responseStatus: action.responseStatus,
				responseMessage: action.responseMessage				
			};
		case CLAIM_TREE_REQUEST:
			return {
				...state,
				urlApiSource: null,
				urlSelectedTreeCount: 1,
				urlTransactionId: null,
				urlEmailId: null,
				choosenTrees: {},
				isFetchingTrees: false,
				responseMessage: action.responseMessage,
				claimResponseStatus : action.claimResponseStatus,
			}
		case RESET_TREE_STATE:
			return {
				...state,
				isLoading: false,
				isFetchingTrees: false,
				responseStatus: apiResponseStatuses.IDLE,
				responseMessage: null,
				treeData: [],
				urlApiSource: null,
				urlSelectedTreeCount: 1,
				urlTransactionId: null,
				urlEmailId: null,
				urlRestaurantName: null,
				urlRestaurantId: null,
				choosenTrees: {},
				transactions: {
					list: [],
					allAvailableTreeCounts: []
				},
				plantedTree: -1,
				walletId: -1,
				allowedTree: -1,
			}
		// when clearing tree transaction choosenTrees wont be cleared
		case CLEAR_TREE_TRANSACTION:
			return {
				...state,
				isLoading: false,
				isFetchingTrees: false,
				responseStatus: apiResponseStatuses.IDLE,
				responseMessage: null,
				treeData: [],
				urlApiSource: null,
				urlSelectedTreeCount: 1,
				urlTransactionId: null,
				urlEmailId: null,
				urlRestaurantName: null,
				urlRestaurantId: null,
				transactions: {
					list: [],
					allAvailableTreeCounts: []
				},
				plantedTree: -1,
				walletId: -1,
				allowedTree: -1,				
			}
		case STORE_MEETING_DATA: {
			// we need to have some kind of breakage of logic here
			// if the user has selected 5 trees and in transactional detail
			// tree count is 1 then we will have issues with counter
			const sizeOfChoosenTrees = Object.keys(state.choosenTrees).length;
			if(sizeOfChoosenTrees > action.urlSelectedTreeCount){
				return {
					...state,
					urlApiSource: action.urlApiSource,
					urlSelectedTreeCount: action.urlSelectedTreeCount,
					urlTransactionId: action.urlTransactionId,
					urlEmailId: action.urlEmailId,
					urlRestaurantName: action.urlRestaurantName,
					urlRestaurantId: action.urlRestaurantId,
					choosenTrees: {}
				};
			}
			// return all the data
			return {
				...state,
				urlApiSource: action.urlApiSource,
				urlSelectedTreeCount: action.urlSelectedTreeCount,
				urlTransactionId: action.urlTransactionId,
				urlEmailId: action.urlEmailId,
				urlRestaurantName: action.urlRestaurantName,
				urlRestaurantId: action.urlRestaurantId,
			};				
			
		}
		case CHOOSE_TREES:
			return {
				...state,
				choosenTrees: {...state.choosenTrees, ...action.choosenTree},
			};

		case UN_CHOOSE_TREES: {
			const key = action.unChoosenTree;
			const { [key]: val, ...rest } = state.choosenTrees;
			return {
				...state,
				choosenTrees: rest
			}
		}

		case UPDATE_PLANTED_TREES_COUNT_STATUS:
			return {
				...state,
				// isLoading: action.isLoading,
				responseStatus: action.responseStatus,
				plantedTree: action.responseData ? action.responseData.totalTree : 0,
				walletId: action.responseData ? action.responseData.walletId : 0
			};
			
		case UPDATE_ALLOWED_TREES_COUNT_STATUS:
			return {
				...state,
				isLoading: action.isLoading,
				responseStatus: action.responseStatus,
				allowedTree: action.responseData ? action.responseData.totalTree : 0,
				transactions: action.responseData ? action.responseData.transactions : state.transactions,
			};
		case CLEAR_CHOOSEN_TREES:
			return {
				...state,
				choosenTrees: {}
			}
		default:
			return state;
	}
}
