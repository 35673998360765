/******************************************
 *  Author : Nikhil Unni
 *  Created On : Wed Oct 27 2021
 *  File : Reducer.js
 *******************************************/
import {
  GET_PATH_INFO_IV
} from './Types';

const initialState = {
  isValid: false,
  videoUrl: '',
  restaurantName: '',
  loadingStatus: 'loading'
};

export default function impossibleVideoReducer(state = initialState, action) {
  switch (action.type) {

    case GET_PATH_INFO_IV:

      return {
        ...state,
        isValid: action.isValid,
        videoUrl: action.videoUrl,
        restaurantName: action.restaurantName,
        loadingStatus: action.loadingStatus
      }

    default:
      return state;
  }
}