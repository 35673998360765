/* eslint-disable no-unused-vars */
/******************************************
 *  Author : Suraj Sharma
 *  Created On : Thu Jan 28 2021
 *  File : TreeTransactionUtil.js
 *******************************************/
/**
 * Since we are taking transactions from api which are not biased on the
 * tree count available.
 * In our app we are using the tree count in the transactions to give user
 * available transactions 
 * 
 * @param Object{
 *  panacea: [...],
 * sustainable_meeting: [...]
 * }
 * 
 * @returns {
 *      list: [ ...transactions_list],
 *      allAvailableTreeCounts: [ 1, 2, 3]
 *   } 
 */

 const TreeTransaction = (apiData, parsedTransactions = {
    list: [],
    allAvailableTreeCounts: []
 }) => new Promise((resolve, reject)=>{

        const localApiData = {...apiData};
		// if the localApiData array is empty that means all elment has been organized
        // time to resolve the promise
        
        let keys = Object.keys(localApiData);

        if(keys.length > 0){
            // since localApiData is object we have to check if all the data for each key entries
            // are empty
            keys.forEach(key => {
                if(localApiData[key].length === 0) {
                    // if localApiData[key].length is 0 then remove that key from localApiData as well as keys
                    keys = keys.filter(k => k !== key);
                    delete localApiData[key];
                }
            });

            // after filtering out unnecessary keys we will check if there are any remaining keys

            keys.forEach(key => {
                // get the first element from the array
                const element = localApiData[key][0];
                const treeCount = element.remaining_tree_count || element.tree_count || element.count;
                // after getting treeCount, check if it's value already exists in parsedTransactions.allAvailableTreeCounts
                // else add the treeCount as a value in parsedTransactions.allAvailableTreeCounts Object
                // remove first element from key Array
                if(!parsedTransactions.allAvailableTreeCounts.includes(parseInt(treeCount))){
                    parsedTransactions.allAvailableTreeCounts.push(parseInt(treeCount));
                }
                parsedTransactions.list.push(element);
                localApiData[key].shift();
            });

            // recursive call the util function
            TreeTransaction(localApiData, parsedTransactions)
            .then((res)=> resolve(res))
        }else {
            resolve(parsedTransactions);
        }
    });

export default TreeTransaction;

